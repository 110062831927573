var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('MobileLogo',{attrs:{"whereFrom":"1","portal":"gugoplay_mobile_home"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clientWidth <= 550.9),expression:"clientWidth <= 550.9"}]},_vm._l((_vm.appGameList),function(item,index){return _c('div',{key:index,staticClass:"app-list eZzVdA"},[_c('div',{class:_vm.homeAdv && index == 0 ? _vm.cTVRlj2 : _vm.cTVRlj},[(index == 0 && _vm.clientWidth <= 550.9)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeAdv),expression:"homeAdv"}],staticClass:"adv",staticStyle:{"grid-area":"adv"}},[_c('ins',{staticClass:"adsbygoogle",staticStyle:{"display":"block","min-height":"314px"},attrs:{"id":"homeAdv","data-ad-client":"ca-pub-9543177256867205","data-ad-slot":_vm.slot,"data-ad-format":"true","data-full-width-responsive":"true"}})]):_vm._e(),_vm._l((item),function(items,indexs){return _c('router-link',{key:indexs,staticClass:"sc-wr3rvk-0 cASSfo sc-963fcq-2 cOWZsC sc-al88rd-1 global-cq",style:(indexs == 0 || indexs == 6 ? { gridArea: 'bigIp' + indexs } : null),attrs:{"to":'/M/details/' +
            items.Name.replace(/\s+/g, '') +
            '?gameId=' +
            items.gameId +
            (_vm.$route.query.channel ? '&channel=' + _vm.$route.query.channel : '')}},[(indexs == 0 || indexs == 6)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(items.iconUrl),expression:"items.iconUrl"}],staticClass:"eoBBYj",attrs:{"alt":"","width":"204px","height":"204px"}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(items.iconUrl),expression:"items.iconUrl"}],staticClass:"eoBBYj",attrs:{"alt":"","width":"94px","height":"94px"}}),_c('span',{staticClass:"sc-963fcq-0 esaxGV global-cq-title"},[_vm._v(_vm._s(items.Name))])])})],2)])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clientWidth > 550.9),expression:"clientWidth > 550.9"}],staticClass:"sc-10l37ae-0 irIQZt"},[_c('div',{class:_vm.homeAdv ? _vm.jMBaZr2 : _vm.jMBaZr},[(_vm.clientWidth > 550.9)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.homeAdv),expression:"homeAdv"}],staticClass:"adv",staticStyle:{"grid-area":"adv"}},[_c('ins',{staticClass:"adsbygoogle",staticStyle:{"display":"block","min-height":"314px"},attrs:{"id":"homeAdv","data-ad-client":"ca-pub-9543177256867205","data-ad-slot":_vm.slot,"data-ad-format":"true","data-full-width-responsive":"true"}})]):_vm._e(),_c('div',{staticStyle:{"display":"contents"}},[_c('ul',{staticClass:"sc-al88rd-0 brZJOk"},_vm._l((_vm.bigImg),function(item,index){return _c('li',{key:index},[_c('router-link',{staticClass:"sc-wr3rvk-0 cASSfo sc-963fcq-2 cOWZsC sc-al88rd-1 global-cq",style:({ gridArea: 'bigIp' + index }),attrs:{"to":'/M/details/' +
                item.Name.replace(/\s+/g, '') +
                '?gameId=' +
                item.gameId +
                (_vm.$route.query.channel
                  ? '&channel=' + _vm.$route.query.channel
                  : '')}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.iconUrl),expression:"item.iconUrl"}],staticClass:"eoBBYj",attrs:{"alt":"","width":"314px","height":"314px"}}),_c('span',{staticClass:"sc-963fcq-0 esaxGV global-cq-title"},[_vm._v(_vm._s(item.Name))])])],1)}),0)]),_c('div',{staticStyle:{"display":"contents"}},_vm._l((_vm.centreImg),function(item,index){return _c('router-link',{key:index,staticClass:"sc-wr3rvk-0 cASSfo sc-963fcq-2 cOWZsC sc-al88rd-1 global-cq",style:({ gridArea: 'ip' + (index + 3) }),attrs:{"to":'/M/details/' +
            item.Name.replace(/\s+/g, '') +
            '?gameId=' +
            item.gameId +
            (_vm.$route.query.channel ? '&channel=' + _vm.$route.query.channel : '')}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.iconUrl),expression:"item.iconUrl"}],staticClass:"eoBBYj",attrs:{"alt":"","width":"204px","height":"204px"}}),_c('span',{staticClass:"sc-963fcq-0 esaxGV global-cq-title"},[_vm._v(_vm._s(item.Name))])])}),1),_c('div',{staticStyle:{"display":"contents"}},_vm._l((_vm.smallImg),function(item,index){return _c('router-link',{key:index,staticClass:"sc-wr3rvk-0 cASSfo sc-963fcq-2 cOWZsC sc-al88rd-1 global-cq",attrs:{"to":'/M/details/' +
            item.Name.replace(/\s+/g, '') +
            '?gameId=' +
            item.gameId +
            (_vm.$route.query.channel ? '&channel=' + _vm.$route.query.channel : '')}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.iconUrl),expression:"item.iconUrl"}],staticClass:"eoBBYj",attrs:{"alt":"","width":"94px","height":"94px"}}),_c('span',{staticClass:"sc-963fcq-0 esaxGV global-cq-title"},[_vm._v(_vm._s(item.Name))])])}),1)])]),_c('div',{staticClass:"bottom"},[_c('BottomList',{attrs:{"whereFrom":"1"}}),_vm._m(0)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-text"},[_c('div',{staticClass:"jXgCKW"},[_vm._v(" Hi! Welcome to GUGOPLAY.com! "),_c('br'),_c('br'),_vm._v(" You can enjoy the best free online games which are playable on mobile, tablets and PC every day. "),_c('br'),_c('br'),_vm._v(" Our editors choose the most addicing games developed by our partners, such as car games, 3d games, cartoon games, skill games, arcade game, sport games, racing games and many other kinds of fashion games often. You can play all our games on your mobile phone, tablet, pad without download or installation, just visit GUGOPLAY.com in your browser such as safari, chrome, firefox, etc, and then enjoy playing the games. "),_c('br'),_c('br'),_vm._v(" So, what are you waiting for? If you feel happy when playing our games, remember to bookmark GUGOPLAY.com and share it to your friends. Have fun! ")])])
}]

export { render, staticRenderFns }